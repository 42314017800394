
import { defineComponent } from 'vue'
import PageContentSidebarList from '@/components/layout/pageContent/sidebar/PageContentSidebarList.vue'
import { servicesSidebarRoutes } from '@/router/sidebarRoutes/servicesRoutes'

export default defineComponent({
  name: 'ServicesSidebar',
  components: { PageContentSidebarList },
  setup() {
    return {
      servicesSidebarRoutes,
    }
  },
})
