
import { defineComponent } from 'vue'
import ServicesSidebar from '@/components/pages/services/ServicesSidebar.vue'
import TmWatchTutorial from '@/components/shared/TmWatchTutorial.vue'

export default defineComponent({
  name: 'Services',
  components: {
    TmWatchTutorial,
    ServicesSidebar,
  },
})
